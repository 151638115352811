<template>
  <div v-if="commonCodeByOrder && gameCount" class="mainBg">
    <div class="main_iv">
      <video autoplay loop muted>
          <source src="../assets/img/main.mp4" type="video/mp4">
      </video>
       <div class="w1314">
          <transition name="fade">
            <ui-swiper />
          </transition>
       </div>
       <div class="tabWrap">
          <div class="tab_01">
            <ul class="">
               <li class="" data-tab="tab-1" v-if="gameCount['casino']" @click="currentTab = 'tab-1'" :class="{'current': currentTab == 'tab-1'}">
                  <img src="../assets/img/menu_casino.png">
                  <h3>{{ $t('front.gnb.livecasino') }}</h3>
               </li>
               <li class="" data-tab="tab-1" v-if="gameCount['hc-casino']" @click="currentTab = 'tab-2'" :class="{'current': currentTab == 'tab-2'}">
                  <img src="../assets/img/menu_hotel.png">
                  <h3>{{ $t('front.gnb.hotelcasino') }}</h3>
               </li>
               <li class="" data-tab="tab-2" v-if="gameCount['slot']" @click="currentTab = 'tab-3'" :class="{'current': currentTab == 'tab-3'}">
                  <img src="../assets/img/menu_slot.png">
                  <h3>{{ $t('front.gnb.slotgame') }}</h3>
               </li>
               <li class="" data-tab="tab-2" v-if="gameCount['h-slot']" @click="currentTab = 'tab-6'" :class="{'current': currentTab == 'tab-6'}">
                  <img src="../assets/img/menu_hotel.png">
                  <h3>{{ $t('front.gnb.hotelslot') }}</h3>
               </li>
               <li class="" data-tab="tab-2" v-if="gameCount['sports']" @click="currentTab = 'tab-4'" :class="{'current': currentTab == 'tab-4'}">
                  <img src="../assets/img/menu_sport.png">
                  <h3>{{ $t('front.gnb.sport') }}</h3>
               </li>
               <li class="" data-tab="tab-2" v-if="gameCount['mini-game']" @click="currentTab = 'tab-5'" :class="{'current': currentTab == 'tab-5'}">
                  <img src="../assets/img/menu_mini.png">
                  <h3>{{ $t('front.gnb.minigame') }}</h3>
               </li>
            </ul>
          </div>
       </div>
    </div>
    <div id="mainobj"><div class="obj1 obj"><img src="../assets/img/obj1.png"></div>
       <section>
         <div id="tab-1" :key="'tab-1'" class="tab-content" v-if="currentTab == 'tab-1'">
           <ul class="betGames betGamesa">
             <template v-for="item in commonCodeByOrder['casino']" v-bind:key="item.vendorKey">
               <li v-if="item.groupCodeName.indexOf('HC-') == -1"
                   @click="onCasinoSelectGame(item.groupCode, item.code)"
               >
                 <div class="mask">
                    <img src="../assets/img/icon_play.svg">
                 </div>
                 <div :style="getBackgroundImage(item)" class="gameImg"></div>
                 <div class="glogoWrap"><em class="glogo" :style="getBackgroundLogoImage(item)"></em></div>
                 <p class="name">
                   {{$i18n.locale !== 'ko' ? item[`codeName${$i18n.locale.toUpperCase()}`] : item.codeName}}
                   <em :class="item.groupCode">{{ $t('front.gnb.livecasino') }}</em>
                 </p>
               </li>
             </template>
           </ul>
         </div><!-- tab-1 -->

         <div id="tab-2" :key="'tab-2'" class="tab-content" v-if="currentTab == 'tab-2'">
           <ul class="betGames betGamesb">
             <template v-for="item in commonCodeByOrder['casino']" v-bind:key="item.vendorKey">
               <li v-if="item.groupCodeName.indexOf('HC-') > -1"
                   @click="onCasinoSelectGame(item.groupCode, item.code)"
               >
                 <div class="mask">
                    <img src="../assets/img/icon_play.svg">
                 </div>
                 <div :style="getBackgroundImage(item)" class="gameImg"></div>
                 <div class="glogoWrap"><em class="glogo" :style="getBackgroundLogoImage(item)"></em></div>
                 <p class="name">
                   {{$i18n.locale !== 'ko' ? item[`codeName${$i18n.locale.toUpperCase()}`] : item.codeName}}
                   <em :class="item.groupCode">{{ $t('front.gnb.hotelcasino') }}</em>
                 </p>
               </li>
             </template>
           </ul>
         </div><!-- tab-2 -->

         <div id="tab-3" :key="'tab-3'" class="tab-content" v-if="currentTab == 'tab-3'">
           <ul class="betGames betGamesc">
             <template v-for="item in commonCodeByOrder['slot']" v-bind:key="item.vendorKey">
               <li v-if="item.groupCodeName.indexOf('H-') === -1"
                   @click="slotOpen('isSlot', 'slot', item.codeName, item.code)"
               >
                  <div class="mask">
                     <img src="../assets/img/icon_play.svg">
                  </div>
                  <div :style="getBackgroundImage(item)" class="gameImg"></div>
                  <div class="glogoWrap"><em class="glogo" :style="getBackgroundLogoImage(item)"></em></div>
                  <p class="name">
                     {{$i18n.locale !== 'ko' ? item[`codeName${$i18n.locale.toUpperCase()}`] : item.codeName}}
                     <em :class="item.groupCode">{{ $t('front.gnb.slotgame') }}</em>
                  </p>
               </li>
             </template>
           </ul>
         </div><!-- tab-3 -->

         <div id="tab-6" :key="'tab-6'" class="tab-content" v-if="currentTab == 'tab-6'">
           <ul class="betGames betGamesa">
             <template v-for="item in commonCodeByOrder['slot']" v-bind:key="item.vendorKey">
               <li :style="getBackgroundImage(item)"
                   v-if="item.groupCodeName.indexOf('H-') > -1"
                   @click="slotOpen('isSlot', 'slot', item.codeName, item.code)"
               >
               <div class="mask">
                  <div>
                     <em class="glogo" :style="getBackgroundLogoImage(item)"></em>
                     <a>
                        {{$i18n.locale !== 'ko' ? item[`codeName${$i18n.locale.toUpperCase()}`] : item.codeName}} ▸
                     </a>
                  </div>
                </div>
                 <p class="name">
                   {{$i18n.locale !== 'ko' ? item[`codeName${$i18n.locale.toUpperCase()}`] : item.codeName}}
                   <em :class="item.groupCode">{{ $t('front.gnb.hotelslot') }}</em>
                 </p>
               </li>
             </template>
           </ul>
         </div><!-- tab-2 -->

         <div id="tab-4" :key="'tab-4'" class="tab-content" v-if="currentTab == 'tab-4'">
           <ul class="betGames betGamesd">
             <template v-for="item in commonCodeByOrder['sport']" v-bind:key="item.vendorKey">
               <li @click="onCasinoSelectGame(item.groupCode, item.code)">
                 <div class="mask">
                   <img src="../assets/img/icon_play.svg">
                 </div>
                 <div :style="getBackgroundImage(item)" class="gameImg"></div>
                 <div class="glogoWrap"><em class="glogo" :style="getBackgroundLogoImage(item)"></em></div>
                 <p class="name">
                   {{$i18n.locale !== 'ko' ? item[`codeName${$i18n.locale.toUpperCase()}`] : item.codeName}}
                   <em :class="item.groupCode">{{ $t('front.gnb.sport') }}</em>
                 </p>
               </li>
             </template>

           </ul>
         </div><!-- tab-4 -->

         <div id="tab-5" :key="'tab-5'" class="tab-content" v-if="currentTab == 'tab-5'">
           <ul class="betGames betGamesa">

             <template v-for="item in commonCodeByOrder['minigame']" v-bind:key="item.vendorKey">
               <li :style="getBackgroundImage(item)"
                   @click="onCasinoSelectGame(item.groupCode, item.code)"
                   @mouseover="item.isHover = true"
                   @mouseout="item.isHover = false"
               >
                 <p class="name">
                   {{$i18n.locale !== 'ko' ? item[`codeName${$i18n.locale.toUpperCase()}`] : item.codeName}}
                   <em :class="item.groupCode">{{ $t('front.gnb.minigame') }}</em>
                 </p>
               </li>
             </template>
           </ul>
         </div><!-- tab-5 -->

       </section>

      <div class="servicecenter">
        <!-- <div class="inout">
           <h4><img src="../assets/img/icon_vvs.svg" />{{ $t('front.main.livecharge') }}<img src="../assets/img/icon_vvs.svg" /></h4>

           <ul @click="goPageByName('')">
             <MARQUEE direction="up" scrollamount="1">
             <template v-for="item in cashInList" :key="item.memId">
               <li>
                 <span><img src="../assets/img/icon_in.svg" />{{item.memId}}</span>
                 <span>{{thousand(Number(item.cashAmt))}} 원</span>
                 <span><img src="../assets/img/icon_time.svg" />{{item.regDate}}</span>
               </li>
             </template>
             <template v-for="item in listMaxCount - cashInList.length" :key="item">
               <li>
                 <span>&nbsp;</span>
                 <span>&nbsp;</span>
                 <span>&nbsp;</span>
               </li>
             </template>
             </MARQUEE>
           </ul>

           <h4 class="mt50"><img src="../assets/img/icon_vvs.svg" />{{ $t('front.main.liveExchange') }}<img src="../assets/img/icon_vvs.svg" /></h4>
           <ul @click="goPageByName('')">
             <MARQUEE direction="up" scrollamount="1">
             <template v-for="item in cashOutList" :key="item.memId">
               <li>
                 <span><img src="../assets/img/icon_mout.svg" />{{item.memId}}</span>
                 <span>{{thousand(Number(item.cashAmt*-1))}} 원</span>
                 <span><img src="../assets/img/icon_time.svg" />{{item.regDate}}</span>
               </li>
             </template>
             <template v-for="item in listMaxCount - cashOutList.length" :key="item">
               <li>
                 <span>&nbsp;</span>
                 <span>&nbsp;</span>
                 <span>&nbsp;</span>
               </li>
             </template>
             </MARQUEE>
           </ul>
        </div> -->
        <div @click="goPageByName('notice')" class="notice">
          <h4><img src="../assets/img/icon_vvs.svg" />{{ $t('front.common.notice') }}<img src="../assets/img/icon_vvs.svg" /></h4>
          <ul>
             <template v-for="item in noticeList" :key="item">
               <li>
                 <span><img src="../assets/img/icon_mega.svg" />{{item.title}}</span>
                 <span><!--<img src="../assets/img/icon_time.svg" />{{item.regDate}}--></span>
                 <span class="con">{{item.content}}</span>
               </li>
             </template>
             <template v-for="item in noticeListMaxCount - noticeList.length" :key="item">
               <li>
                 <span>&nbsp;</span>
                 <span>&nbsp;</span>
                 <span>&nbsp;</span>
               </li>
             </template>
          </ul>
        </div>
        <a class="banner">
          <img src="../assets/img/main_banner.png" />
        </a>
      </div>
    </div>

    <div class="mainPopWrap">
      <template v-for="(item) in popupList" v-bind:key="item.bannerIdx">
        <div class="mainPop" v-if="item.delYn == 'N' && !item.isClose" :data-idx="item.bannerIdx">
          <div class="closeWrap">
            <a class="close" @click="popupCloseByCookie(item)">{{$t('front.main.today')}}</a>
            <a class="close" @click="popupClose(item)">{{$t('front.main.close')}}</a>
          </div>
          <img :src="item.bannerImg" />
        </div>
      </template>
    </div>
  </div>

   <!--div class="loginmodal">
      <div class="mloading-container">
         <div class="mloading"></div>
         <div class="mloading-text">loading</div>
      </div>
   </div-->
</template>

<script>

import '@/styles/common.css'
import { getPopupCookie, setPopupCookie, tokenCheck } from '@/libs/auth-helper'

import { getMain, getMainCashStatus, getMainNotice } from '@/api/main'
import { mapState } from 'vuex'

import store from '@/store'
import UiSwiper from '@/components/ui/UiSwiper.vue'
import { thousand } from '../libs/utils'

export default {
  name: 'Index.vue',
  components: {
    UiSwiper
  },
  created () {
    // this.emitter.emit('Loading', true)
    // this.emitter.emit('Loading', true)
    // setTimeout(() => {
    //
    // }, 5000)
    // setTimeout(() => {
    //   this.emitter.emit('Loading', false)
    // }, 8000)
    this.loadMain()
    this.popupMerge()
    this.loadNotice()
    this.loadCashStatus()
  },
  data () {
    return {
      currentTab: 'tab-1',
      popupList: {},
      cashInList: [],
      cashOutList: [],
      noticeList: [],
      listMaxCount: 5,
      selectedMenu: '',
      noticeListMaxCount: 5
    }
  },
  watch: {
    popup () {
      this.popupMerge()
    },
    popupLogin () {
      this.popupMerge()
    },
    async userData () {
      await store.dispatch('storePopup')
      if (this.userData) {
        await store.dispatch('storePopupLogin')
      }
      await this.popupMerge()
    }
  },
  computed: {
    ...mapState([
      'userData',
      'commonCode',
      'commonCodeByOrder',
      'popup',
      'popupLogin',
      'banner',
      'gameCount'
    ])

  },
  methods: {
    thousand,
    loadCashStatus () {
      getMainCashStatus({}).then(res => {
        const result = res.data
        if (result.resultCode === '0') {
          console.log(result.data)
          this.cashInList = result.data.cashInList.slice(0, this.listMaxCount)
          this.cashOutList = result.data.cashOutList.slice(0, this.listMaxCount)
        }
      })
    },
    loadNotice () {
      getMainNotice({}).then(res => {
        const result = res.data
        if (result.resultCode === '0') {
          console.log(result)
          this.noticeList = result.data.boardList.slice(0, this.noticeListMaxCount)
        }
      })
    },
    getBackgroundImage (item) {
      try {
        const image = require('../assets/img/game_main_pc_' + item.code + '_off.png')
        const imageOff = require('../assets/img/game_main_pc_' + item.code + '_off.png')
        if (image && imageOff) {
          if (item.isHover) {
            return { backgroundImage: 'url(' + image + ')' }
          } else {
            return { backgroundImage: 'url(' + imageOff + ')' }
          }
        } else {
          return { backgroundImage: 'url()' }
        }
      } catch (e) {
        return { backgroundImage: 'url()' }
      }
    },
    getBackgroundLogoImage (item) {
      try {
        const image = require('../assets/img/glogo' + item.code + '.png')
        return { backgroundImage: 'url(' + image + ')' }
      } catch (e) {
        return { backgroundImage: 'url()' }
      }
    },
    popupMerge () {
      const popupLogin = this.userData ? this.popupLogin : []
      const pList = this.popup.concat(popupLogin)

      this.popupList = {}

      pList.forEach(item => {
        if (!item.isClose) {
          const key = item.bannerIdx
          const siteId = process.env.VUE_APP_SITE_ID || 'moka'
          const name = siteId + '_popup_' + item.bannerIdx
          const cookie = getPopupCookie(name)
          if (cookie) {
            item.isClose = true
          } else {
            item.isClose = false
          }
          if (!this.popupList[key]) {
            this.popupList[key] = item
          }
        }
      })
    },
    popupClose (item) {
      item.isClose = true
    },
    popupCloseByCookie (item) {
      item.isClose = true
      setPopupCookie(item)
    },
    slotOpen (event, groupCode, codeName, code) {
      this.emitter.emit(event, { groupCode, codeName, code })
      this.emitter.emit('Loading', false)
    },
    loadMain () {
      if (this.userData) {
        getMain({}).then(res => {
          // console.log(res)
        })
      }
    }
  },
  async beforeRouteEnter (to, from, next) {
    await tokenCheck()
    // if (result) {
    next()
    // }
  }
}
</script>
<style scoped>
</style>
<style>
.mainSlide img{width:100%;}
.mainSlide .swiper-slide>a{width:100%;display:block;}
.mainSlide .swiper-slide>a img{display:block;width:100%;height: ;object-fit:cover;object-position:center;}
.swiper-button-next, .swiper-button-prev {bottom: 50px;top: auto;}
.swiper-button-next {
  background-image: url(../assets/img/slideright.png) !important;
  background-size: 11px 20px !important;
  background-repeat: no-repeat;
  background-position: center;
  right: 50%;
  bottom: 3px;
  margin-right: -640px;
  z-index: 11;
}
.swiper-button-prev {
  background-image: url(../assets/img/slideleft.png) !important;
  background-size: 11px 20px !important;
  background-repeat: no-repeat;
  background-position: center;
  left: 50%;
  bottom: 3px;
  margin-left: -640px;
  z-index: 11;
}
.swiper-button-next:after, .swiper-button-prev:after {content: '';}
.swiper-button-next:hover, .swiper-button-prev:hover {opacity: 1;}
.swiper-pagination {bottom: 0 !important;gap: 100px;background-color: ;height: 50px;display: flex;justify-content: center;align-items: center;}
.swiper-pagination-bullet {width: 105px; min-height: 19px; background: transparent; opacity: 0.3; color: #fff;}
.swiper-pagination-bullet-active {border-radius: 0;opacity: 1;}
</style>
<style scoped src="@/styles/header.css"></style>
<style scoped src="@/styles/footer.css"></style>
<style scoped src="@/styles/main.css"></style>
